<template>
    <section id="Systemeng" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Hardware is the building block of each system</h2>
          <p style="padding-top:20px">Our system and hardware engineers are skilled designers, developers and testers of computer systems</p>
          <p >The advancement in technology has touched all areas of our lives, from the devices we use to the machines that work. All this stems from an advanced system and hardware that can process information and execute the software instructions accurately with speed. The embedded software services expect an advanced hardware tech-friendly system. Our company makes sure all our systems and hardware are up to the latest market trends.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="systemhead">{{ obj.header }}</h4>
              <h6 class="systempar">{{obj.subtitile}}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/System-ENGINEERING.jpg',
        header: 'SYSTEM ENGINEERING',
        subtitile: 'Ensure perfect functioning system',
        caption: 'We deal with all forms of system operations that include developing, manufacturing and regular maintenance. It is basically an interdisciplinary approach to enable the realization of the success of systems.'
      },
      {
        path: 'services/HARDWARE-ENGINEERING.jpg',
        header: 'HARDWARE ENGINEERING',
        subtitile: 'Design and maintain Hardware',
        caption: 'We design, develop, test, integrate and manufacture all forms of hardware that contribute to the proper functioning of the overall system. Some of the hardware we work with includes the circuit board, hard drive, system components and memory devices.'
      },
      {
        path: 'services/EMBEDDED-SYSTEMS.jpg',
        header: 'EMBEDDED SYSTEMS',
        subtitile: 'Embedded systems upgrade living',
        caption: 'We work with embedded systems in a way where software engineering is applied to common everyday non-computer devices we use. We excel at maintaining and designing from the basic to the final functioning of the product.'
      },
      {
        path: 'services/ROBOTICS.jpg',
        header: 'ROBOTICS',
        subtitile: 'Man and machines',
        caption: 'The age where machine is part of daily lives, robotics has become a field of study where machine works and performs the functions man does. This field includes electronics, computer science, Artificial intelligence, mechatronics and nano technology.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Systemeng {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}
.systempar{
  font-size: 14px;
  line-height: 20px;
}
.services .icon-box .systemhead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 14px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>