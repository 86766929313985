<template>
   <!-- Start Hero Area -->
    <section class="hero-area Systemhead overlay">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12 col-12">
                    <div class="hero-content">
                        <h1 class="Syshead" style="margin:50px 0px">Systems and Hardware Engineering</h1>
                        <h4 style="margin-bottom:40px" >Embedded computer systems are becoming a part of our everyday decision making.</h4>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Hero Area -->
</template>

<style >
/*======================================
    Hero Area CSS
========================================*/
.Systemhead {
  position: relative;
  padding: 200px 0 100px 0;
  background-image: url("../assets/images/App/System-Background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 100px 0;
  }
}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 70px 0;
  }
}


.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  padding: 0px 100px;
}

.hero-area .hero-content h4 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.hero-area .hero-content .Syshead {
  font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    font-family: Raleway;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: 0px 3px 8px #00000017;
    text-transform: uppercase;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  font-size: 15px;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .hero-area .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
  }

  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }
  
  .hero-area .hero-content .button .btn:last-child {
    margin: 0;
  }
}

</style>
