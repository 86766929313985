<template>
    <Header/>
    <SystemEngineeringheader/>
    <SystemEngineeringSection/>
    <Systemicons/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import SystemEngineeringSection from '@/components/SystemEngineeringSection.vue'
import Systemicons from '@/components/Systemicons.vue'
import SystemEngineeringheader from '@/components/SystemEngineeringheader.vue'

export default {
  components: {
     Header,
    Footer,
    SystemEngineeringSection  ,
    Systemicons,
    SystemEngineeringheader
  }
}
</script>