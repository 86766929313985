<template>
    <section id="Sysicon" class="services">
      <div class="container">

        <div class="section-title">
          <h4>We create machines that are a part of all aspect of our lives.</h4>
          <p>Our professionals are well versed in the advancement of technology and the pace with which it moves and creates impact in our day-to-day lives.</p>
       </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="embedimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/Systems-Performance.png',
        header: 'Systems Performance',
        caption: 'As part of system engineering, the proper performance of the system is the main goal of the engineer. All issues related to the software, the connections, the LAN and other issues of system are dealt with.'
      },
      {
         path: 'background/Hardware-Products.png',
        header: 'Hardware Products',
        caption: 'We provide engineered hardware products for the system that are designed, tested for proper functioning and finally readied for the manufacturing process.'
      },
      {
         path: 'background/Machine-Integrated.png',
        header: 'Machine Integrated Age',
        caption: 'In the present age where man and machine work alike, we incorporate all the advanced technological advancements of robots and embedded systems programmed to do a set of tasks.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
img.embedimage {
    width: 15%;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Sysicon {
    padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>